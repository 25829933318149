<template>
    <div>
      <el-button type="success" @click="showAddDayModal" round>Add A Day</el-button>
      <article>
        <section>
          <div class="category_dialogs">
            <el-dialog :title="`Add A Day`" :visible.sync="addDayModal" :destroy-on-close="true"
              :before-close="handleClose" style="text-align: left">
              <div v-loading="submitting">
                <el-form :model="dayForm" :rules="rules" label-position="top" ref="dayForm"
                  class="demo-dayForm2">
  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="tourDayTitle" prop="tourDayTitle">
                        <el-input v-model="dayForm.tourDayTitle"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="tour Day" prop="tourDay">
                        <el-input v-model="dayForm.tourDay"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="tourMainDestination" prop="tourMainDestination">
                        <el-input textarea v-model="dayForm.tourMainDestination"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="tourMeals" prop="tourMeals">
                        <el-input textarea v-model="dayForm.tourMeals"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="tourAccomodation" prop="tourAccomodation">
                        <el-select v-model="dayForm.tourAccomodation" filterable placeholder="Select">
                          <el-option
                            v-for="item in hotels"
                            :key="item.hotelID"
                            :label="item.hotelTitle"
                            :value="item.hotelID">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="Tour Description" prop="tourDescription">
                      <editor
                        api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                        :init="{
                          height: 300,
                          menubar: false,
                          plugins: [
                            'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                            'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                            'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                          ],
                          toolbar:
                            'undo redo | casechange blocks | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                        }"
                        placeholder="Tour Description ..."
                        v-model="dayForm.tourDescription"
                      />
                    </el-form-item>
                  </div>
                </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item label="Day Photo">
                        <el-upload class="upload-demo" drag action="''" :on-change="handleDayPhotoPreview"
                          :on-remove="handleDayPhotoRemove" :file-list="categoryPhotoFileLists" :auto-upload="false"
                          :multiple="false">
                          <div v-if="!isUploadingFile">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                              Drop file here or <em>click to upload</em>
                            </div>
                          </div>
                          <div v-else>
                            <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                              :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                            <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                              :stroke-width="4"></el-progress>
                            <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                              {{
    imageUploadingProgress == 100
    ? "Uploaded"
    : "Uploading..."
                              }}
                            </div>
                          </div>
                          <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                        </el-upload>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button type="success" round @click="addDay('dayForm')">Add Day</el-button>
                  </el-form-item>
                </el-form>
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Bakyala Safaris, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <small>Design by <a href="https://ovalspace.co"
                        style="color: black;"><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  <script>
  import Editor from "@tinymce/tinymce-vue";
  export default {
    components: {
      editor: Editor,
    },
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        selectedDayPhotoFile: null,
        isUploadingFile: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        categoryPhotoFileLists: [],
        addDayModal: false,
        submitting: false,
        dayForm: {
          tourDayTitle: "",
          tourDay: "",
          tourMainDestination: "",
          tourMeals: "",
          tourAccomodation: "",
          tourDescription: "",
        },
        rules: {
          tourDayTitle: [
            {
              required: true,
              message: "Title is required",
              trigger: "blur",
            },
          ],
  
          tourMainDestination: [
            {
              required: true,
              message: "Main Destination is required",
              trigger: "blur",
            },
          ],
          tourDay: [
            {
              required: true,
              message: "Day is required",
              trigger: "blur",
            },
          ],
  
          tourMeals: [
            {
              required: true,
              message: "Meals are required",
              trigger: "blur",
            },
          ],
          tourAccomodation: [
            {
              required: true,
              message: "Accomodation is required",
              trigger: "blur",
            },
          ],
          tourDescription: [
            {
              required: true,
              message: "Description is required",
              trigger: "blur",
            },
          ],
          
        },
        hotels: [],

        
      };
    },
    methods: {
      handleClose(done) {
        done();
      },
      handleDayPhotoPreview(file) {
        console.log(file.raw);
        this.categoryPhotoFileLists = [file];
        this.selectedDayPhotoFile = file.raw;
      },
      handleDayPhotoRemove() {
        this.selectedDayPhotoFile = null;
      },
  
      showAddDayModal() {
        this.addDayModal = true;
        this.getHotels();
        this.dayForm.tourDayTitle = "";
        this.dayForm.tourMainDestination = "";
      },
  
      async addDay(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("tourDayTitle", this.dayForm.tourDayTitle);
              formData.append("tourMainDestination", this.dayForm.tourMainDestination);
              formData.append("tourDay", this.dayForm.tourDay);
              formData.append("tourMeals", this.dayForm.tourMeals);
              formData.append("tourAccomodation", this.dayForm.tourAccomodation);
              formData.append("tourDescription", this.dayForm.tourDescription);
              formData.append("tourIdeaID", this.$route.params.tourIdeaID);
              formData.append("tourFeaturingPhoto", this.selectedDayPhotoFile);
              formData.append("createdBy", this.$store.state.userId);
              let response = await this.$http.post(`tour-days`, formData, {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              });
              if (
                response.data.success &&
                response.data.message == "DAY_CREATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Day added successfully",
                  type: "success",
                });
                this.addDayModal = false;
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to add day",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
      
      async getHotels() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`hotels/${this.$route.params.countryID}`);
          if (request.data.success) {
            this.hotels = request.data.hotels;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch hotels now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
  </script>
  
  
  <style>
  .category_dialogs .el-dialog {
    width: 40%;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .category_dialogs .el-dialog {
      width: 50%;
    }
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .category_dialogs .el-dialog {
      width: 60%;
    }
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .category_dialogs .el-dialog {
      width: 80%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .category_dialogs .el-dialog {
      width: 80%;
    }
  }
  </style>
  
<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/countries' }">Countries</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: `/${$route.params.countryName}/${$route.params.countryID}/tour-ideas` }">Tour Ideas</el-breadcrumb-item>
                  <el-breadcrumb-item>Tour Days</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>Days in {{ $route.params.tourIdeaTitle }}:</b></h3>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Name"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <div>
                <AddEditTourDayDialog @re-fresh="getTourDays" />
              </div>
            </div>
            <hr />
  
            <div
              v-loading="loading || is_changing"
              class="table-responsive mb-5"
            >
              <data-tables
                :data="tourDays"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getTourDays"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Days Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Tour Days</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Featuring Photo"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="75px"
                >
                  <template slot-scope="scope">
                    <img
                      :src="scope.row.tourFeaturingPhoto"
                      style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                    >
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Tour Idea"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span>Day: <strong>{{ scope.row.tourDay }}</strong></span><br/>
                    <span>Title: <strong>{{ scope.row.tourDayTitle }}</strong> </span><br />
                    <small>Add On: <strong>{{ formattedDate(scope.row.createdDate) }}</strong> </small><br />
                  </template>
                </el-table-column>
                
                <el-table-column
                  label="Day Info"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span>Accommodation: <strong>{{ scope.row.tourAccomodation }}</strong></span><br/>
                    <span>Main Destination: <strong>{{ scope.row.tourMainDestination }}</strong> </span><br />
                    <small>Meals: <strong>{{ scope.row.tourMeals }}</strong> </small><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Status"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="100px"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex;">
                      <el-tooltip
                        :content="scope.row.is_active ? 'Active' : 'Deactivated'"
                        placement="top"
                      >
                        <el-switch
                          v-model="scope.row.is_active"
                          @change="switchStatus(scope.row.isActive, scope.row.tourDayID)"
                          active-color="#13ce66"
                          inactive-color=""
                        >
                        </el-switch>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>

                <!-- <el-table-column
                label="Operations"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="auto"
                >
                <template slot-scope="scope">
                    <el-button
                    @click="$router.push({ path: `/${$route.params.countryName}/${$route.params.countryID}/${scope.row.tourDayTitle}/${scope.row.tourDayID}` })"
                    type="success"
                    round
                    >Days</el-button>
                </template>
                </el-table-column>
   -->
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                        <el-button
                          type="warning"
                          :disabled="false"
                          @click="showEditPickLocationData(scope.row)"
                          icon="el-icon-edit"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          :disabled="false"
                          @click="deleteTourDay(scope.row.tourDayID)"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
      <EditTourDayDialog
        :showEditTourDayEditDialog="showEditTourDayEditDialog"
        :dayData="dayData"
        @closeEditTourDayDialog="closeEditTourDayDialog"
        @re-fresh="getTourDays"
      />
  
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  import AddEditTourDayDialog from "../components/dialogs/add-day-dialog.vue";
  import EditTourDayDialog from "../components/dialogs/edit-day-dialog";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
      AddEditTourDayDialog,
      EditTourDayDialog,
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showEditTourDayEditDialog: false,
        tourDays: [],
        dayData: {},
        decoded_token: {},
        decoded_token2: {},
        first_name: "",
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "fullname",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getTourDays();
    },
  
    methods: {
      showEditPickLocationData(dayData) {
        this.showEditTourDayEditDialog = true;
        this.dayData = dayData;
      },
  
      closeEditTourDayDialog() {
        this.showEditTourDayEditDialog = false;
      },
  
      async getTourDays() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`tour-days/fetch-all/${this.$route.params.tourIdeaID}`);
          if (request.data.success) {
            this.tourDays = request.data.tourDays;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Tour Days now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteTourDay(tourDayID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Tour Day. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`tour-days/${tourDayID}`);
              if (
                request.data.success &&
                request.data.message === "DAY_DELETED_SUCCESSFULLY"
              ) {
                this.getTourDays();
                this.$notify({
                  title: "Success",
                  message: "Tour Day deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Tour Day, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
  
      
  
      async switchStatus(activation_status, tourDayID) {
        try {
          this.is_changing = true;
          let request = await this.$http.patch(
            `tour-days/change_is_active_status/${tourDayID}`, {
              is_active: activation_status,
            }
          );
          if (
            request.data.success &&
            request.data.message == "DAY_IS_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
          ) {
            this.getTourDays();
            this.showSuccessMessage("Success", );
                return this.$notify({
                  title: "Success",
                  message: "Pickup status changed",
                  type: "success",
                });
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.is_changing = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change Pickup status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.is_changing = false;
        }
      },
  
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>